
import { Component, Vue, Watch } from "vue-property-decorator";

import Thumbnail from "@/components/Thumbnail.vue";
import FeaturedThumbnail from "@/components/FeaturedThumbnail.vue";
import ClassCarousel from '@/components/ClassCarousel.vue';
import BlogCard from "@/components/BlogCard.vue";
import BlogCardFull from "@/components/BlogCardFull.vue";
import { clone } from "ramda";
import { Blog } from '@/store/modules';
import moment from "moment";
import router from "@/router";

@Component({
    name: "Categories",
    components: {
        Thumbnail,
        FeaturedThumbnail,
        ClassCarousel,
        BlogCard,



    },
})
export default class Home extends Vue {
    loading = true;
    errorLoading = '';
    post: any = {
        id: '',
        image: '',
        title: '',
        description: '',
        category: '',
        date: '',
        author: ''
    }
    prevPost = {
        id: '',
        image: '',
        title: '',
        description: '',
        category: '',
        date: '',
        author: ''
    };
    nextPost = {
        id: '',
        image: '',
        title: '',
        description: '',
        category: '',
        date: '',
        author: ''
    };
    postKey = 0

    getFormattedDate(date: any) {
        return moment(date).format("MMMM Do, YYYY")
    }

    @Watch('$route.params')
    async onSlugChange() {
        await this.getPosts()
    }

    async getPosts() {
        const item = await Blog.getBlogPost(this.postSlug);
        this.prevPost = item?.prev
        this.nextPost = item?.next
        this.post = clone(item.curr)

    }

    async mounted() {
        await this.getPosts()
    }

    get postSlug() {
        return this.$route.params.slug;
    }

    clickNext() {
        router.push({ name: 'post', params: { slug: this.nextPost.id } })
    }

    clickPrev() {
        router.push({ name: 'post', params: { slug: this.prevPost.id } })
    }

    clickBlogRouter() {
        router.push({ name: 'blog' })
    }

    clickHomeRouter() {
        router.push({ name: 'home' })
    }
}
